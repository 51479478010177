/************************************************
* IMAGE
*************************************************/

    .callToAction {
        margin-bottom: 3rem;
        background-color: $primary;
        background-position: center;
        background-size: cover;

        .content {
            padding: 0 5%;
            margin: 3rem 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .text {
            font-family: $thirdFont;
            color: white;
            font-size: 3.5rem;
            line-height: 1;
            text-align: center;
            position: relative;
            z-index: 10;
            width: 100%;
        }

        a.button {
            margin-top: 3rem;
        }
    }



/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .callToAction {
            margin-bottom: 5rem;

            .content {
                padding: 0;
            }
        }
    }

    @media(min-width: 768px) {
        .callToAction {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .content {
                width: 100%;
            }

            .text {
                font-size: 4.2rem;
            }
        }
    }

    @media(min-width: 1024px) {
        .callToAction {
            .content {
                width: 75%;
                margin: 6rem 0;
            }

            a.button {
                margin-top: 5rem;
            }
        }
    }

    @media(min-width: 1400px) {
        .callToAction {
            .content {
                margin: 10rem 0;
            }

            .text {
                font-size: 5rem;
            }
        }
    }

    @media(min-width: 1600px) {
        .callToAction {
            margin-bottom: 7rem;
        }
    }
