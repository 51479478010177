/*****************
* Colors
******************/

$primary: #cb2a1e; /*Rot*/
$secondary: #a08d82; /*Hellbraun */

$lightGray: #f1f1f1; /*Beige*/
$darkGray: #cacaca;

$fontColor: #151616; /*Dunkelgrau*/
$fontColorLight: #151616; /*Dunkelgrau*/
$fontColorDark: #29170d; /*Dunkelbraun */


/*****************
* Fonts
******************/

$primaryFont: 'MotionPicture', cursive;
$secondaryFont: 'Palanquin', sans-serif;
$thirdFont: 'MotionPicture', cursive;
