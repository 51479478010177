.instagram {
	text-align: center;
	padding-bottom: 7rem;

	.sliderNavWrapper {
		position: relative;
		.owl-prev,
		.owl-next {
			position: absolute;
			bottom: 38%;
			z-index: 2;
			font-size: 3rem;
			color: $primary;
			i:hover {
				cursor: pointer;
			}
		}
		.owl-prev {
			left: 0;
		}
		.owl-next {
			right: 0;
		}
	}

	#instaSlider {
		z-index: 1;
	}
	
	.owl-item {
		img {
			max-width: 100%;
			max-height: auto;
		}
	}
}


@media only screen and (min-width: 1024px)  {
	.instagram {
		#instaSlider {
			padding: 0 5em;
		}
	}
}