/************************************************
* DEALER
*************************************************/

    .dealer {
        margin-bottom: 3rem;
        background-color: $primary;
        padding: 2rem;

        h2 {
            margin-top: 0;
            color: white;
            width: 100%;
        }

        p {
            color: white;
            width: 100%;
        }

        form {
            position: relative;
            margin-top: 2.5rem;
        }

        input {
            border: none;
            font-size: 1.8rem;
            font-family: $secondaryFont;
            color: $fontColor;
            padding: 1rem 6rem 1rem 1.5rem;
            border-radius: 0;
            width: 100%;
        }

        ::placeholder {
            color: $fontColorLight;
        }

        button {
            border: none;
            position: absolute;
            display: block;
            background-color: transparent;
            right: .5rem;
            top: 50%;
            margin: 0;
            padding: 1rem;
            transform: translateY(-50%);

            i {
                font-size: 2.5rem;
                color: $fontColor;
                transition: all .2s ease-in-out;
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .dealer {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 1025px) {
        .dealer button:hover i {
            color: $primary;
        }
    }

    @media(min-width: 1200px) {
        .dealer {
            padding: 3rem;
        }
    }

    @media(min-width: 1500px) {
        .dealer {
            padding: 4rem;
        }
    }

    @media(min-width: 1600px) {
        .dealer {
            margin-bottom: 7rem;
        }
    }
