/************************************************
* QUOTE
*************************************************/

    .quote {
        margin-bottom: 3rem;
        position: relative;
        background-position: center;
        background-size: cover;

        &::after {
            content: '';
            position: absolute;
            background-color: rgba($secondary, .3);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
        }

        .text {
            padding: 0 5%;
            margin: 3rem 0;
        }

        .cite {
            font-family: $thirdFont;
            color: $fontColorDark;
            font-size: 3.5rem;
            line-height: 1;
            text-align: center;
            position: relative;
            z-index: 10;
        }

        .source {
            font-size: 1.8rem;
            color: $fontColor;
            text-align: center;
            margin-top: 2rem;
            position: relative;
            z-index: 10;

            &::before {
                content: '– ';
            }

            &::after {
                content: ' –';
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

   @media(min-width: 500px) {
        .quote {
            margin-bottom: 5rem;
        }
   }

    @media(min-width: 768px) {
        .quote {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .text {
                width: 100%;
            }

            .cite {
                font-size: 4.2rem;
            }
        }
    }

    @media(min-width: 1024px) {
        .quote {
            .text {
                width: 75%;
                padding: 0;
                margin: 6rem 0;
            }
        }
    }

    @media(min-width: 1400px) {
        .quote {
            .text {
                margin: 10rem 0;
            }

            .cite {
                font-size: 5rem;
            }
        }
    }

    @media(min-width: 1600px) {
        .quote {
            margin-bottom: 7rem;

            .source {
                margin-top: 3rem;
            }
        }
    }
