/************************************************
* formLayout20
*************************************************/

    main > .inner > .wrap > div.formLayout20 {
        @include calcFullSettings(92%, 1.24%);
    }

    .formLayout20 {
        background-color: $lightGray;
        padding: 3rem 0;

        h1,
        h2 {
            margin-top: 0;
        }

        /* static text */
        h2 + div {
            margin-bottom: 4rem;
        }

        a {
            text-decoration: none;
            color: $primary;

            @media(min-width: 1025px) {
                &:hover {
                    color: rgba($primary, .7);
                }
            }
        }

        /* immer um ein form element (label + div) */
        .form-group {
            margin-bottom: 3rem;
        }


        /* Grid Row */

        .row {
            width: 100%;
            margin-bottom: 2rem;

            + .row h2 {
                margin-top: 3rem;
            }

        }

        /* Label */

        label {
            width: 100%;
            color: $fontColor;;
            font-size: 2rem;
            display: block;
            margin-bottom: 1rem;

            &.form-check-label > span {
                display: block;
                margin-right: .5em;
                width: 90%;
                width: calc(100% - 45px);
                float: right;
                font-weight: 300;
                line-height: 1.2em;
            }
        }


        /* Fieldset */

        fieldset {
            margin: 0 0 2rem 0;
            padding: 0;
            border: 0;

            legend {
                font-size: 2rem;
                font-weight: 500;
                color: $primary;
                margin-bottom: 2rem;
                padding: 0;
            }

            > .form-group,
            .clearfix {
                margin-bottom: 1rem;
            }
        }


        /* Input & Textarea */

        .input {
            width: 100%;

            input[type=text],
            textarea {
                border: none;
                padding: 1.3rem;
                width: 100%;
                font-family: $secondaryFont;
                font-size: 2rem;
                color: $fontColor;
            }

            textarea {
                height: 20rem;
            }
        }

        /* Submit */

        .actions {
            padding: 3rem 0 0 0;
            width: 100%;
            text-align: right;

            button {
                background-color: $primary;
                border: none;
                color: white;
                cursor: pointer;
                font-size: 1.9rem;
                font-weight: 300;
                padding: 1.2rem 2rem;
                transition: .3s ease-in-out;
            }
        }
    }



/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 768px) {
        .formLayout20 {
            padding: 5rem 0;
        }

        main > .inner > .wrap > div.formLayout20 {
            @include calcFullSettings(94%, 1.24%);
        }
    }

    @media (min-width: 900px) {
        form > .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            > div {
                width: 48%;
            }
        }

        .formLayout20 {
            .row {
                margin-bottom: 0;
            }

            .inputs-list .form-check {
                width: 48%;
            }

            .row .row div:last-child {
                .form-group {
                    margin-bottom: 0;
                }
            }
        }

    }

    @media(min-width: 1024px) {
        .formLayout20 {
            padding: 4rem 0;
        }
    }

    @media(min-width: 1200px) {
        .formLayout20 {
            padding: 5rem 0;
        }
    }

    @media(min-width: 1400px) {
        .formLayout20 form {
            padding: 0 10%;
        }
    }

    @media(min-width: 1600px) {
        .formLayout20 form {
            padding: 0 15%;
        }
    }

    @media(min-width: 1670px) {
        main > .inner > .wrap > div.formLayout20 {
            margin-left: -150px;
            margin-right: -150px;
            width: 1950px;
            padding-left: 175px;
            padding-right: 175px;
        }
    }
