/************************************************
* MAPS
*************************************************/

    .maps {
        margin-bottom: 4rem;

        .embed-container {
            position: relative;
            padding-bottom: 70%;
            height: 0;
            overflow: hidden;
            width: 100%;
            height: auto;
        }

        .embed-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .maps {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 1600px) {
        .maps {
            margin-bottom: 7rem;
        }
    }