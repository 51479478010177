/************************************************
* HISTORY
*************************************************/

    .history {
        margin-bottom: 3rem;

        h2 {
            margin: 0 0 2rem 0;
            text-align: center;
            width: 100%;
        }

        .entry {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2rem;
        }

        .inner {
            position: relative;
            padding: 0 0 0 5.5rem;

            &::before {
                content: '';
                display: block;
                border-left: 2.5px solid $darkGray;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 3rem;
                width: 1px;
                z-index: 10;
            }
        }

        .year {
            background-color: $lightGray;
            border-radius: 50%;
            position: relative;
            width: 100%;
            width: 6.5rem;
            height: 6.5rem;
            margin-bottom: 2rem;

            span {
                font-size: 2rem;
                font-weight: 700;
                line-height: 1;
                text-align: center;
                color: $fontColor;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .image {
            margin-bottom: 2rem;

            img {
                display: block;
            }
        }

        .text {
            background-color: $lightGray;
            padding: 1.5rem;

            h3 {
                color: $primary;
                margin: 0;
            }

            p:first-of-type {
                margin-top: 1rem;
            }

            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .history {
            margin-bottom: 5rem;

            .year {
                width: 8rem;
                height: 8rem;

                span {
                    font-size: 2.4rem;
                }
            }

            .inner {
                padding-left: 7rem;

                &::before {
                    left: 3.75rem;
                }
            }

            .text {
                padding: 2rem;
            }
        }
    }

    @media(min-width: 700px) {
        .history {
            .year {
                width: 12rem;
                height: 12rem;

                span {
                    font-size: 3rem;
                }
            }

            .inner {
                padding-left: 12rem;

                &::before {
                    left: 5.75rem;
                }
            }

            .text {
                padding: 3rem;
            }
        }
    }

    @media(min-width: 840px) {
        .history {
            h2 {
                margin-bottom: 3rem;
            }

            .year {
                margin-bottom: 4rem;
            }

            .entry {
                justify-content: center;
                margin-bottom: 4rem;

                &:nth-of-type(2n) .inner .image {
                    order: 2;
                }
            }

            .inner {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-left: 0;

                &::before {
                    left: 50%;
                }
            }

            .image {
                width: 46%;
                margin-bottom: 0;
            }

            .text {
                width: 46%;
                padding: 2rem;
            }
        }
    }

    @media(min-width: 1300px) {
        .history .text {
            padding: 3rem;
        }
    }

    @media(min-width: 1500px) {
        .history {
            .image,
            .text {
                width: 42%;
                align-self: center;
            }

            h2 {
                margin-bottom: 5rem;
            }
        }
    }

    @media(min-width: 1600px) {
        .history {
            margin-bottom: 7rem;

            .image,
            .text {
                width: 40%;
            }
        }
    }
