/************************************************
* PRODUCTDETAILS
*************************************************/

    .productDetails {
        margin-bottom: 3rem;
        background-color: $primary;
        padding-top: 3rem;
        padding-bottom: 4rem;

        .nutritionFacts {
            h2 {
                color: white;
                margin-top: 0;
            }

            .box {
                background-color: white;
                padding: 1.5rem;
            }

            h3 {
                font-size: 1.6rem;
                text-transform: uppercase;
                margin: 0 0 .5rem 0;
                color: #111111;
                font-weight: 500;
            }

            h3 + p {
                margin-top: 0;
            }

            p + h3 {
                margin-top: 2rem;
            }

            p {
                font-size: 1.7rem;
                color: $fontColor;

                &.small {
                    font-size: 1.5rem;
                }
            }

            ul {
                padding: 0;

                li {
                    list-style-type: none;
                    font-size: 1.7rem;
                    padding: .5rem 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    color: $fontColor;

                    &:nth-of-type(2n) {
                        background-color: $lightGray;
                    }

                    strong {
                        width: 48%;
                        font-weight: normal;
                        margin-right: 4%;
                    }

                    span {
                        width: 48%;
                    }
                }
            }
        }
        
        .dealer {
            padding: 0;
            margin: 6rem 0 0 0;
        }
    }
    

/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .productDetail {
            margin-bottom: 5rem;
        }
    }    

    @media(min-width: 768px) {
        .productDetails {
            .dealer {
                padding-right: 25%;
            }
        }
    }

    @media(min-width: 800px) {
        .productDetails {
            .nutritionFacts {
                padding-right: 25%;
            }
        }
    }

    @media(min-width: 1000px) {
        .productDetails {
            padding-top: 4rem;
            padding-bottom: 5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .nutritionFacts {
                width: 48%;
                padding: 0;

                h2 {
                    width: 100%;
                }
            }
    
            .dealer {
                width: 48%;
                padding: 0;
                margin: 0;
                align-self: center;
            }
        }
    }

    @media(min-width: 1300px) {
        .productDetails .nutritionFacts .box {
            padding: 2.5rem;
        }
    }

    @media(min-width: 1500px) {
        .productDetails {
            padding-top: 6rem;
            padding-bottom: 7rem;
        }
    }

    @media(min-width: 1600px) {
        .productDetails {
            margin-bottom: 7rem;

            .nutritionFacts .box {
                padding: 3.5rem;
            }
        }
    }