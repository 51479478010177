/************************************************
* formLayout0
*************************************************/

    .formLayout0 {
        padding: 3rem 0;

        h1,
        h2 {
            margin-top: 0;
        }

        /* static text */
        h2 + div {
            margin-bottom: 4rem;
        }

        a {
            text-decoration: none;
            color: $primary;

            @media(min-width: 1025px) {
                &:hover {
                    color: rgba($primary, .7);
                }
            }
        }


        /* immer um ein form element (label + div) */
        .form-group {
            margin-bottom: 3rem;
        }


        /* Grid Row */

        .row {
            width: 100%;
            margin-bottom: 2rem;

            + .row h2 {
                margin-top: 3rem;
            }

        }

        /* Label */

        label {
            width: 100%;
            color: $fontColor;;
            font-size: 2rem;
            display: block;
            margin-bottom: 1rem;
        }


        /* Fieldset */

        fieldset {
            margin: 0 0 2rem 0;
            padding: 0;
            border: 0;

            legend {
                font-size: 2rem;
                font-weight: 500;
                color: $primary;
                margin-bottom: 2rem;
                padding: 0;
            }

            > .form-group,
            .clearfix {
                margin-bottom: 1rem;
            }
        }


        /* Input & Textarea */

        .input {
            width: 100%;

            input,
            textarea {
                border: none;
                padding: 1.3rem;
                width: 100%;
                font-family: $secondaryFont;
                font-size: 2rem;
                color: $fontColor;
                background-color: #f2f2f2;
            }

            textarea {
                height: 20rem;
            }
        }

        /* Submit */

        .actions {
            padding: 3rem 0 0 0;
            width: 100%;
            text-align: right;

            button {
                background-color: $primary;
                border: none;
                color: white;
                cursor: pointer;
                font-size: 1.9rem;
                font-weight: 300;
                padding: 1.2rem 2rem;
                transition: .3s ease-in-out;
            }
        }
    }


/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/

    .select2-container--default{
        font-size: 2rem;
        width: 40%;

        .select2-selection--single{
            background-color: white;
            font-size: 2rem;
            border-radius: 0;
            border: none;
            padding: 1.7rem 1.3rem;
            padding-right: 4rem;
            height: auto;

            .select2-selection__arrow {
                height: auto;
                bottom: 0;
                width: 5rem;
                top: 0;
                right: 0;
                border: 1px solid #7f7f7f;
                background-color: #7f7f7f;
                border-left: 0;

                b {
                    border: none;

                    &::after{
                        @extend %icons;
                        content: '\e807';
                        position: absolute;
                        top: -.8rem;
                        left: -.8rem;
                        font-size: 2.5rem;
                        color: white;
                    }
                }
            }

            .select2-selection__rendered {
                color: $fontColor;
                font-size: 2rem;
                padding: 0;
                li:before{
                    content: '';
                }
            }
        }


        // Container im offenen Zustand
        &.select2-container--open{
            .select2-selection--single .select2-selection__arrow b {
                border: none;
                &::after{
                    content: '\e80a';
                }
            }
        }

        .select2-results__option {
            padding: 1rem;
            color: $fontColor;
        }


        /* Hintergrund für aktive Auswahl*/
        .select2-results__option[aria-selected="true"]{
            background-color: #f2f2f2;
        }


        /* Hintergrund für gehoverte Auswahl*/
        .select2-results__option--highlighted[aria-selected]{
            background-color: #d3d3d3;
            color: $fontColor;
        }

        .select2-selection--multiple{
            font-size: 2rem;
            border-radius: 0;
            padding: .8rem .7rem .7rem .7rem;
            padding-right: 4rem;
            height: auto;
            line-height: 1.8rem;

            li {
                line-height: 1.5;
                font-size: 2rem;
            }

            .select2-selection__choice {
                margin-top: .2rem;
            }

            .select2-search--inline::before{
                content: '';
                input {
                    font-size: 2rem;
                }
            }
        }
    }


    /* Hintergrund generell für die Optionen */
    .select2-dropdown{
        background-color: white;
        border-color: #a7a7a7;
        border-width: 1px;
        border-radius: 0;
    }




/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 400px) {
        .select2-container--default{
            width: 30%;
        }
    }

    @media(min-width: 600px) {
        .select2-container--default{
            width: 20%;
        }
    }

    @media(min-width: 768px) {
        .formLayout0 {
            padding: 5rem 0;
        }
    }

    @media(min-width: 800px) {
        .select2-container--default{
            width: 17%;
        }
    }


    @media (min-width: 900px) {
        form > .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > div.row {
                width: 48%;
            }
        }

        .formLayout0 {
            .row {
                margin-bottom: 0;
            }

            .inputs-list .form-check {
                width: 48%;
            }

            .row .row div:last-child {
                .form-group {
                    margin-bottom: 0;
                }
            }
        }

        .formLayout0 > form > .row > div:last-child {
            > .row {
                height: 100%;

                > div:last-child {
                    height: 60%;

                    > div {
                        height: 100%;

                        textarea {
                            height: 95.5%;
                        }
                    }
                }
            }
        }

        .select2-container--default{
            width: 30%;
        }
    }

    @media(min-width: 1024px) {
        .formLayout0 {
            padding: 4rem 0;
        }

        .select2-container--default{
            width: 25%;
        }
    }

    @media(min-width: 1200px) {
        .formLayout0 {
            padding: 5rem 0;
        }
    }

    @media(min-width: 1300px) {
        .formLayout0 {
            .form-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 4rem;
            }

            label {
                width: 22%;
                margin: 0;
                padding-top: 1.5rem;
            }

            .input {
                width: 75%;
            }
        }

        .formLayout0 > form > .row > div:last-child {
            > .row > div:last-child > div textarea {
                height: 100%;
            }
        }
    }

    @media(min-width: 1550px) {
        .select2-container--default{
            width: 22%;
        }
    }

