/************************************************
* merchantForm
*************************************************/

    .merchantForm {
        background-color: $primary;
        margin-bottom: 6rem;

        .text {
            padding: 3rem;

            p, h2, h3 {
                color: #fff;
            }

            h2 + p {
                margin-top: 0;
            }

            p:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    
    form.merchantForm {
      background-color: $primary;
      padding: 3rem;
      display: flex;
      position: relative;

      input {
          border: none;
          font-size: 1.8rem;
          font-family: 'Open Sans', sans-serif;
          color: $fontColor;
          padding: 1rem 6rem 1rem 1.5rem;
          border-radius: 0;
          width: 100%;
      }

      ::placeholder {
          color: $fontColorLight;
      }

      button {
          border: none;
          position: absolute;
          display: block;
          background-color: #fff;
          color: $fontColor;
          right: 3rem;
          top: 50%;
          margin: 0;
          padding: .1rem 1rem;
          transform: translateY(-50%);

          i {
              font-size: 2.5rem;
              color: $fontColor;
              transition: all .2s ease-in-out;
          }
      }
    }

    .merchantList {
        font-size: 1.4rem;
        
        .item {
            margin-bottom: 2rem;

            .merchant {
                margin-bottom: 1rem;
                display: block;

                address {
                    font-style: normal;
                }

                .postcode, .locality {
                    display: inline-block;
                    margin-right: .5rem;
                }

                a {
                    color: $primary;
                }
            }

            .googleMaps {
                flex: 0 1 50%;
                max-width: 500px;;
                height: 200px;
            }

            iframe {
                border: 0px;
                width: 100%;
                height: 100%;
            }
        }
    }

    ul.f3-widget-paginator {
        flex: 0;
        clear: both; 
        margin: 2em 0;
        padding: 0;
        list-style:none;
        font-size: 1.6rem;

        li {
            display: inline-block;
            margin-right: 6px;
            color: darken($primary, 30%);

            &.current {
                color: darken($primary, 30%);
                padding: 0.3em .3em;
            }
        }

        li, a {
            color: $primary;
        }

        a {
            padding: 0.3em .3em;
            display: inline-block;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .merchantForm {
            margin-bottom: 5rem;
        }
    }

    @media screen and (min-width: 700px) {
        .merchantList .item {
            display: flex;

            .merchant {
                flex: 1 1 auto;
            }
        }
    }

    @media(min-width: 1600px) {
        .merchantForm {
            margin-bottom: 7rem;
        }
    }