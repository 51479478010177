/************************************************
* TEXTTWOCOL
*************************************************/

    .textTwoCol {
        margin-bottom: 3rem;

        h2 {
            width: 100%;
        }

        ul {
            padding: 0;

            li {
                list-style-type: none;
                font-size: 2rem;
                color: $fontColorLight;
                margin-bottom: 1rem;
                padding-left: 2rem;
                position: relative;

                &::before {
                    @extend %icons;
                    content: '\e806';
                    color: $primary;
                    position: absolute;
                    font-size: 2.4rem;
                    left: -.5rem;
                    top: .2rem;
                } 

                &.checkmark::before {
                    @extend %icons;
                    content: '\e803';
                    color: $primary;
                    position: absolute;
                    left: -.5rem;
                    top: .3rem;
                }
            }
        }

        .col {
            margin-bottom: 4rem;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .textTwoCol {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 600px) {
        .textTwoCol {
            padding-right: 15%;
        }
    }

    @media(min-width: 700px) {
        .textTwoCol {
            padding-right: 25%;
        }
    }

    @media(min-width: 1024px) {
        .textTwoCol {
            padding-right: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        h2.fullwidth {
            width: 100%;
            margin: 0;
        }

        .col {
            width: 48%;
            margin-bottom: 0;
        }
    }

    @media(min-width: 1400px) {
        .textTwoCol {
            padding: 0 10%;
        }
    }

    @media(min-width: 1600px) {
        .textTwoCol {
            margin-bottom: 7rem;
            padding: 0 15%;
        }
    }