/************************************************
* INGREDIENTS
*************************************************/

    .ingredients {
        margin-bottom: 3rem;
        padding: 0 8%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h2.fullwidth {
            width: 100%;
            text-align: center;
        }

        .ingredient {
            margin-bottom: 3rem;
        }

        .image {
            margin-bottom: 1rem;

            img {
                display: block;    
            }
        }

        .title {
            text-align: center;
            font-size: 2rem;
            font-weight: 300;
            line-height: 1.4;
            color: $fontColor;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 400px) {
        .ingredients {
            padding: 0 15%;
        }
    }

    @media(min-width: 500px) {
        .ingredients {
            margin-bottom: 5rem;
            padding: 0 20%;
        }
    }

    @media(min-width: 600px) {
        .ingredients {
            padding: 0 10%;

            .ingredient {
                width: 48%;
                margin-right: 4%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @media(min-width: 670px) {
        .ingredients {
            padding: 0 15%;
        }
    }

    @media(min-width: 768px) {
        .ingredients {
            padding: 0;

            .ingredient {
                margin-bottom: 0;
                width: 23.5%;
                margin-right: 2%;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 2%;
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }
            }
        }
    }

    @media(min-width: 1024px) {
        .ingredients h2.fullwidth {
            margin-bottom: 2rem;
        }
    }

    @media(min-width: 1200px) {
        .ingredients {
            padding: 0 10%;
        }
    }
    
    @media(min-width: 1600px) {
        .ingredients {
            padding: 0 15%;
            margin-bottom: 7rem;
        }
    }