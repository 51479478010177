/************************************************
* FILTER
*************************************************/

    .filter {
        margin-bottom: 3rem;
        padding: 3rem 0;
        background-color: $secondary;

        .headline {
            font-family: $thirdFont;
            font-size: 3.7rem;
            color: white;
            text-align: center;
            margin-bottom: 3rem;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .option {
        width: 47%;
        margin-right: 6%;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        .inner {
            width: 100%;
            padding-top: 100%;
            border-radius: 50%;
            background-color: white;
            margin-bottom: 3rem;
            position: relative;
            text-decoration: none;
            display: block;
            position: relative;

            @media(min-width: 1025px) {
                &:hover {
                    cursor: pointer;

                    .text {
                        color: $secondary;
                    }

                    .icon {
                        color: darken($secondary, 20%);
                    }
                }
            }
        }

        .text {
            position: absolute;
            font-family: $thirdFont;
            font-size: 3rem;
            color: $secondary;
            text-align: center;
            width: 100%;
            bottom: 2.5rem;
            transition: all .3s ease-in-out;
        }

        .icon {
            font-size: 6rem;
            display: block;
            position: absolute;
            color: #cacaca;
            top: 35%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            transition: all .3s ease-in-out;

            i::before {
                margin: 0;
            }
        }
    }



/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 400px) {
        .option .icon {
            font-size: 8rem;
            top: 40%;
        }
    }

    @media(min-width: 440px) {
        .option {
            .text {
                font-size: 3.3rem;
                bottom: 3rem;
            }
        }
    }

    @media(min-width: 500px) {
        .filter {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 530px) {
        .filter {
            .headline {
                margin-bottom: 3rem;
            }

            .content {
                justify-content: center;
            }
        }

        .option {
            padding: 0 1%;
            width: 31%;
            margin-right: 3.5%;

            &:nth-of-type(2n){
                margin-right: 3.5%;
            }

            &:nth-of-type(3n){
                margin-right: 0;
            }

            .text {
                font-size: 2.8rem;
                bottom: 2.5rem;
            }

            .icon {
                font-size: 6rem;
            }
        }
    }

    @media(min-width: 700px) {
        .option {
            .text {
                font-size: 3.2rem;
                bottom: 3rem;
            }

            .icon {
                font-size: 8rem;
            }
        }
    }

    @media(min-width: 750px) {
        .option {
            padding: 0;
            width: 18.5%;
            margin-bottom: 0;
            margin-right: 1.875%;

            &:nth-of-type(2n){
                margin-right: 1.875%;
            }

            &:nth-of-type(3n){
                margin-right: 1.875%;
            }

            &:nth-of-type(4n){
                margin-right: 1.875%;
            }

            &:nth-of-type(5n) {
                margin-right: 0;
            }

            .inner {
                margin-bottom: 0;
            }

            .text {
                font-size: 2.6rem;
                bottom: 2.1rem;
            }

            .icon {
                font-size: 5rem;
            }
        }
    }

    @media(min-width: 850px) {
        .option {
            .icon {
                font-size: 7rem;
            }
        }
    }

    @media(min-width: 900px) {
        .option {
            .text {
                font-size: 3rem;
            }
        }
    }

    @media(min-width: 1024px) {
        .filter {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        .option .text {
            font-size: 3.2rem;
            bottom: 2.7rem;
        }
    }

    @media(min-width: 1025px) {
        .option .inner:hover .text {
            color: darken($secondary, 25%);
        }
    }

    @media(min-width: 1100px) {
        .option {
            .icon {
                font-size: 8rem;
            }
        }
    }

    @media(min-width: 1100px) {
        .option {
            .icon {
                top: 42%;
            }
        }
    }

    @media(min-width: 1250px) {
        .filter {
            .headline {
                font-size: 4rem;
            }

            .text {
                font-size: 3.5rem;
            }
        }
    }

    @media(min-width: 1400px) {
        .option {
            padding: 0 2%;
        }
    }

    @media(min-width: 1500px) {
        .filter {
            .headline {
                font-size: 4.3rem;
            }
        }
    }

    @media(min-width: 1600px) {
        .filter {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 7rem;
            padding-top: 6rem;
            padding-bottom: 6rem;

            .headline {
                font-size: 4.5rem;
                margin: 0;
                padding-right: 3rem;
                text-align: left;
                align-self: center;
                width: 25%;
            }

            .content {
                width: 75%;
            }
        }

        .option {
            padding: 0 .5%;
        }
    }
