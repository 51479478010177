
/************************************************
* FONT
*************************************************/

  @import url('https://fonts.googleapis.com/css?family=Palanquin:300,500');

  @font-face {
    font-family: 'MotionPicture';
    src: url('/fileadmin/sanbeam/fonts/3670BB_0_0.eot');
    src: url('/fileadmin/sanbeam/fonts/3670BB_0_0.eot?#iefix') format('embedded-opentype'),
         url('/fileadmin/sanbeam/fonts/3670BB_0_0.woff2') format('woff2'),
         url('/fileadmin/sanbeam/fonts/3670BB_0_0.woff') format('woff'),
         url('/fileadmin/sanbeam/fonts/3670BB_0_0.ttf') format('truetype');
  }

/************************************************
* Base Font Setting
*************************************************/

  html {
    font-size: 62.5%; // 1 rem = 10px
  }

  body {
    font-family: $secondaryFont;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

/************************************************
 * TYPO
 ************************************************/

  h1 {
    font-size: 2.7rem;
    font-weight: 500;
    line-height: 1.2;
    color: $fontColor;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.2;
    color: $fontColor;
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    color: $fontColor;
  }

  p {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.4;
    color: $fontColorLight;
  }

  a {


    &:hover {

    }

  }

  .button,
  button {
    display: inline-block;
    font-size: 1.9rem;
    line-height: 1.5;
    text-decoration: none;
    padding: 1rem 3rem 1.3rem 3rem;
    background-color: $primary;
    color: white;
    transition: all .3s ease-in-out;
    text-align: center;

    &.white {
      background-color: white;
      color: $fontColor;
    }
  }


/************************************************
* RESPONSIVE
*************************************************/

  @media(min-width: 1024px) {
    h1 {
      font-size: 2.9rem;
    }
  }

  @media(min-width: 1025px) {
    .button:hover,
    button:hover {
      /*background-color: rgba($primary, .7);*/
      cursor: pointer;
      border-radius: 2rem;
    }

    .button.white:hover,
    button.white:hover {
      /*background-color: rgba(255, 255, 255, .7);*/
      cursor: pointer;
      border-radius: 2rem;
    }
  }

  @media(min-width: 1300px) {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.6rem;
    }

    h3 {
      font-size: 2.2rem;
    }

  }

  @media(min-width: 1500px) {
    .button,
    button {
      font-size: 2rem;
    }
  }
