/************************************************
* IMAGE
*************************************************/

    .imageElement {
        margin-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        img {
            display: block;
        }
    }



/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .imageElement {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 1600px) {
        .imageElement {
            margin-bottom: 7rem;
        }
    }