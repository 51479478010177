/************************************************
* ICONS (fontello.com)
*************************************************/

@font-face {
  font-family: 'sanbeam-icons';
  src: url('/fileadmin/sanbeam/fonts/sanbeam-icons.eot?94217044');
  src: url('/fileadmin/sanbeam/fonts/sanbeam-icons.eot?94217044#iefix') format('embedded-opentype'),
       url('/fileadmin/sanbeam/fonts/sanbeam-icons.woff2?94217044') format('woff2'),
       url('/fileadmin/sanbeam/fonts/sanbeam-icons.woff?94217044') format('woff'),
       url('/fileadmin/sanbeam/fonts/sanbeam-icons.ttf?94217044') format('truetype'),
       url('/fileadmin/sanbeam/fonts/sanbeam-icons.svg?94217044#sanbeam-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icons{
    font-family: "sanbeam-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %icons;
}

.icon-menu:before { content: '\e800'; } /* '' */
.icon-left-open-big:before { content: '\e801'; } /* '' */
.icon-right-open-big:before { content: '\e802'; } /* '' */
.icon-check:before { content: '\e803'; } /* '' */
.icon-right:before { content: '\e804'; } /* '' */
.icon-left:before { content: '\e805'; } /* '' */
.icon-dot:before { content: '\e806'; } /* '' */
.icon-down-open-mini:before { content: '\e807'; } /* '' */
.icon-left-open-mini:before { content: '\e808'; } /* '' */
.icon-right-open-mini:before { content: '\e809'; } /* '' */
.icon-up-open-mini:before { content: '\e80a'; } /* '' */
.icon-alkoholfrei:before { content: '\e80b'; } /* '' */
.icon-alkoholhaltig:before { content: '\e80c'; } /* '' */
.icon-vegan:before { content: '\e80d'; } /* '' */
.icon-vollmilch:before { content: '\e80e'; } /* '' */
.icon-zartbitter:before { content: '\e80f'; } /* '' */
.icon-left-1:before { content: '\f177'; } /* '' */
.icon-right-1:before { content: '\f178'; } /* '' */
