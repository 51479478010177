/************************************************
* ANIMATIONS
*************************************************/   

    /* hide elements first */
      .hide {
        opacity: 0;
      }


    /* fadeInUp effect from animate.css */

      @keyframes fadeInUp {
        from {
          opacity: 0;
          transform: translate3d(0, 12%, 0);
        }

        to {
          opacity: 1;
          transform: none;
        }
      }

      .fadeInUp-active {
        animation: fadeInUp 1s;
      }


    /* fadeIn effect from animate.css */

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .fadeIn-active {
      animation: fadeIn 2s;
    }


    /* FadeInLeft effect from animate.css */

      @keyframes fadeInLeft {
        from {
          opacity: 0;
          transform: translate3d(-8%, 0, 0);
        }

        to {
          opacity: 1;
          transform: none;
        }
      }

      .fadeInLeft-active {
        animation: fadeInLeft 1s;
      }


    