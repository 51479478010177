/************************************************
* PRODUCTHEADER
*************************************************/

    .productHeader {
        margin-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h1 {
            text-align: center;
            width: 100%;
        }

        .image {
            margin-bottom: 3rem;
            width: 100%;

            img {
                margin: 0 auto;
                display: block;

                &.img2 {
                    display: none;
                }
            }

            &:hover {
                img {
                    &.img1 {
                        display: none;
                    }

                    &.img2 {
                        display: block;
                    }
                }
            }
        }

        .left,
        .right {
            width: 48%;

            .image {
                width: 55%;
                position: absolute;
                left: 50%;
                top: 16%;
                transform: translateX(-50%);
                margin: 0;
            }

            .text {
                position: absolute;
                font-family: $thirdFont;
                font-size: 2.7rem;
                color: $fontColor;
                text-align: center;
                width: 100%;
                bottom: 10% !important;
                transition: all .3s ease-in-out;
            }

        }

        .option {
            width: 100%;

            .inner:hover {
                cursor: inherit;
            }
        }

        .inner {
            background-color: $lightGray;
        }
    }



/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 400px) {
        .productHeader {
            .left,
            .right {
                .text {
                    font-size: 3.3rem;
                }
            }
        }
    }

    @media(min-width: 500px) {
        .productHeader {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 530px) {
        .productHeader {
            padding: 0 10%;
        }
    }

    @media(min-width: 630px) {
        .productHeader {
            padding: 0 15%;
        }
    }

   @media(min-width: 730px) {
        .productHeader {
            padding: 0;

            h1 {
                margin-bottom: 3rem;
            }

            .image {
                width: 47%;
                order: 1;
                align-self: center;
                margin: 0;
            }

            .left,
            .right {
                width: 23%;

                .option:nth-of-type(2n) .inner {
                    margin: 0;
                }

                .text {
                    font-size: 3rem;
                }
            }

            .left {
                order: 0;
            }

            .right {
                order: 2;
            }

            .option .inner {
                margin-bottom: 1.5rem;
            }
        }
   }

    @media(min-width: 950px) {
        .productHeader {
            padding: 0 5%;

            .left,
            .right {
                .image {
                    width: 50%;
                    margin-top: 1rem;
                }
            }

            .option {
                width: 90%;
                margin-bottom: 3rem;
            }
        }
    }

    @media(min-width: 1024px) {
        .productHeader {
            margin-bottom: 7rem;
            padding: 0 8%;

            .option .inner {
                margin-bottom: 2.5rem;
            }

            .left,
            .right {
                .text {
                    font-size: 3.2rem;
                }
            }
        }
    }

    @media(min-width: 1025px) {
        .productHeader {
            .option:hover {
                .text {
                    color: $fontColor;
                }
            }
        }
    }

    @media(min-width: 1300px) {
        .productHeader {
            padding: 0 15%;

            .option {
                .inner {
                    margin-bottom: 3.5rem;
                }

                .image {
                    width: 90%;
                }
            }

            .left,
            .right {
                .image {
                    width: 50%;
                    margin-top: 1rem;
                }
            }
        }
    }

    @media(min-width: 1500px) {
        .productHeader {
            .left,
            .right {
                .text {
                    font-size: 3.5rem;
                }
            }
        }
    }

    @media(min-width: 1600px) {
        .productHeader {
            margin-bottom: 10rem;
            padding: 0 18%;

            .option .inner {
                margin-bottom: 5rem;
            }
        }
    }
