/************************************************
* General CONTENT Stylings
*************************************************/

    /* myfonts counter */
    @import url("//hello.myfonts.net/count/3670bb");

    footer {
        background-color: $fontColorDark;
        padding: 2rem 0;
        font-size: 1.8rem;

        .buttonLinks {
            width: 100%;
            display: inline-block;
            padding-bottom: 3rem;
            
            a {
                color: #fff;
                display: inline-block;
                font-size: 1.8rem;
                text-decoration: none;
                transition: .3s ease-in-out;
                white-space: nowrap;
                
                &::before {
                    background-color: $primary;
                    color: #fff;
                    display: inline-block;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: .5rem;
                    margin-right: 1rem;
                    min-width: 5rem;
                    padding: .8rem;
                    position: relative;
                    text-align: center;
                    top: .2rem;
                    transition: .3s ease-in-out;
                }
                &.instagram::before {
                    content: '\f16d';
                    font-family: sanbeam-icons;
                    font-size: 3rem;
                }       
                &.partnerInfos::before {
                    content: 'B2B';
                }

                &:hover {
                    &::before {
                        border-radius: 20px;
                    }
                }
            }
        }
        
        .footerNav {
            width: 100%;

            div {


                a {
                    color: white;
                    text-decoration: none;
                    font-weight: normal;
                    transition: all .2s ease-in-out;
                    font-size: 1.9rem;
                }

                ul {
                    margin: 0;
                    padding: 0 0 1rem 0;
                    list-style: none;
                    display: block;
                }

                li {
                    display: block;
                }


            }    
        }


        p {
            color: lighten($fontColorDark, 70%);
            margin-top: 0;
            line-height: 1.5em;
        }

    }

/************************************************
* RESPONSIVE
*************************************************/

    @media(min-width: 500px) {
        .buttonLinks {
            a {
                margin: 0 2rem;

            }
        }



    }
    @media(min-width: 640px) {
        footer {

            .buttonLinks {
                text-align: center;
            }
            .footerNav {

                flex-wrap: wrap;

                display: flex;
                div {
                    flex: 1 1 50%;
                    padding-right: 2%;
                }

            }
        }
    }



    @media(min-width: 768px) {
        footer {
            .footerNav {
                a {
                    font-size: 1.6rem;
                    margin: 0;
                }
            }

            p a.phone {
                color: lighten($fontColorDark, 65%);
            }            

        }
    }

    @media(min-width: 1024px) {
        footer {
            padding: 3rem 0;

            .footerNav {

                div {
                    flex: 1 1 25%;
                    padding-right: 2%;
                }

                ul {    
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                a {
                    font-size: 1.8rem;
                }
            }
        }
    }

    @media(min-width: 1025px) {
        footer .footerNav a:hover {
            color: $secondary;
        }
    }

    @media(min-width: 1400px) {
        footer {
            padding: 4rem 0;
        }        
    }

