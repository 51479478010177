/************************************************
* TEASERBOX
*************************************************/

    .teaserbox {
        margin-bottom: 3rem;
        background-color: $lightGray;
        padding: 2rem;

        h2 {
            margin-top: 0;
        }

        .text {
            a.button {
                margin-top: 1rem;
                float: right;
                align-self: flex-end;
            }

            &.icon {
                position: relative;
                padding-left: 5rem;

                img {
                    position: absolute;
                    left: 0; 
                }
            }

            h3 {
                margin-bottom: 1rem;
            }

            p {
                margin-top: 0;
            }
        }
    }



/************************************************
* RESPONSIVE
*************************************************/
    
    @media(min-width: 500px) {
        .teaserbox {
            margin-bottom: 5rem;
        }
    }

    @media(min-width: 1200px) {
        .teaserbox {
            padding: 3rem;
        }
    }

    @media(min-width: 1500px) {
        .teaserbox {
            padding: 4rem;

            .text a.button {
                margin-top: 2rem;
            }
        }
    }

    @media(min-width: 1600px) {
        .teaserbox {
            margin-bottom: 7rem;
        }
    }