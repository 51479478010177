@charset "UTF-8";
/************************************************
* Base Definitions
*************************************************/
/* clearfix */
/* Breakpoints */
/*****************
* Colors
******************/
/*Rot*/
/*Hellbraun */
/*Beige*/
/*Dunkelgrau*/
/*Dunkelgrau*/
/*Dunkelbraun */
/*****************
* Fonts
******************/
/************************************************
* FONT
*************************************************/
@import url("https://fonts.googleapis.com/css?family=Palanquin:300,500");
@import url("//hello.myfonts.net/count/3670bb");
@font-face {
  font-family: 'MotionPicture';
  src: url("/fileadmin/sanbeam/fonts/3670BB_0_0.eot");
  src: url("/fileadmin/sanbeam/fonts/3670BB_0_0.eot?#iefix") format("embedded-opentype"), url("/fileadmin/sanbeam/fonts/3670BB_0_0.woff2") format("woff2"), url("/fileadmin/sanbeam/fonts/3670BB_0_0.woff") format("woff"), url("/fileadmin/sanbeam/fonts/3670BB_0_0.ttf") format("truetype"); }

/************************************************
* Base Font Setting
*************************************************/
html {
  font-size: 62.5%; }

body {
  font-family: "Palanquin", sans-serif;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************************************************
 * TYPO
 ************************************************/
h1 {
  font-size: 2.7rem;
  font-weight: 500;
  line-height: 1.2;
  color: #151616; }

h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2;
  color: #151616; }

h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  color: #151616; }

p {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
  color: #151616; }

.button,
button {
  display: inline-block;
  font-size: 1.9rem;
  line-height: 1.5;
  text-decoration: none;
  padding: 1rem 3rem 1.3rem 3rem;
  background-color: #cb2a1e;
  color: white;
  transition: all .3s ease-in-out;
  text-align: center; }
  .button.white,
  button.white {
    background-color: white;
    color: #151616; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 1024px) {
  h1 {
    font-size: 2.9rem; } }

@media (min-width: 1025px) {
  .button:hover,
  button:hover {
    /*background-color: rgba($primary, .7);*/
    cursor: pointer;
    border-radius: 2rem; }
  .button.white:hover,
  button.white:hover {
    /*background-color: rgba(255, 255, 255, .7);*/
    cursor: pointer;
    border-radius: 2rem; } }

@media (min-width: 1300px) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.6rem; }
  h3 {
    font-size: 2.2rem; } }

@media (min-width: 1500px) {
  .button,
  button {
    font-size: 2rem; } }

/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'sanbeam-icons';
  src: url("/fileadmin/sanbeam/fonts/sanbeam-icons.eot?94217044");
  src: url("/fileadmin/sanbeam/fonts/sanbeam-icons.eot?94217044#iefix") format("embedded-opentype"), url("/fileadmin/sanbeam/fonts/sanbeam-icons.woff2?94217044") format("woff2"), url("/fileadmin/sanbeam/fonts/sanbeam-icons.woff?94217044") format("woff"), url("/fileadmin/sanbeam/fonts/sanbeam-icons.ttf?94217044") format("truetype"), url("/fileadmin/sanbeam/fonts/sanbeam-icons.svg?94217044#sanbeam-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before, .textTwoCol ul li::before, .textTwoCol ul li.checkmark::before, .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
  font-family: "sanbeam-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-menu:before {
  content: '\e800'; }

/* '' */
.icon-left-open-big:before {
  content: '\e801'; }

/* '' */
.icon-right-open-big:before {
  content: '\e802'; }

/* '' */
.icon-check:before {
  content: '\e803'; }

/* '' */
.icon-right:before {
  content: '\e804'; }

/* '' */
.icon-left:before {
  content: '\e805'; }

/* '' */
.icon-dot:before {
  content: '\e806'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e807'; }

/* '' */
.icon-left-open-mini:before {
  content: '\e808'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e809'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e80a'; }

/* '' */
.icon-alkoholfrei:before {
  content: '\e80b'; }

/* '' */
.icon-alkoholhaltig:before {
  content: '\e80c'; }

/* '' */
.icon-vegan:before {
  content: '\e80d'; }

/* '' */
.icon-vollmilch:before {
  content: '\e80e'; }

/* '' */
.icon-zartbitter:before {
  content: '\e80f'; }

/* '' */
.icon-left-1:before {
  content: '\f177'; }

/* '' */
.icon-right-1:before {
  content: '\f178'; }

/* '' */
/************************************************
* Library's (optional)
*************************************************/
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/************************************************
* CONTENT
*************************************************/
/************************************************
* TEASERBOX
*************************************************/
.teaserbox {
  margin-bottom: 3rem;
  background-color: #f1f1f1;
  padding: 2rem; }
  .teaserbox h2 {
    margin-top: 0; }
  .teaserbox .text a.button {
    margin-top: 1rem;
    float: right;
    align-self: flex-end; }
  .teaserbox .text.icon {
    position: relative;
    padding-left: 5rem; }
    .teaserbox .text.icon img {
      position: absolute;
      left: 0; }
  .teaserbox .text h3 {
    margin-bottom: 1rem; }
  .teaserbox .text p {
    margin-top: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .teaserbox {
    margin-bottom: 5rem; } }

@media (min-width: 1200px) {
  .teaserbox {
    padding: 3rem; } }

@media (min-width: 1500px) {
  .teaserbox {
    padding: 4rem; }
    .teaserbox .text a.button {
      margin-top: 2rem; } }

@media (min-width: 1600px) {
  .teaserbox {
    margin-bottom: 7rem; } }

/************************************************
* DEALER
*************************************************/
.dealer {
  margin-bottom: 3rem;
  background-color: #cb2a1e;
  padding: 2rem; }
  .dealer h2 {
    margin-top: 0;
    color: white;
    width: 100%; }
  .dealer p {
    color: white;
    width: 100%; }
  .dealer form {
    position: relative;
    margin-top: 2.5rem; }
  .dealer input {
    border: none;
    font-size: 1.8rem;
    font-family: "Palanquin", sans-serif;
    color: #151616;
    padding: 1rem 6rem 1rem 1.5rem;
    border-radius: 0;
    width: 100%; }
  .dealer ::placeholder {
    color: #151616; }
  .dealer button {
    border: none;
    position: absolute;
    display: block;
    background-color: transparent;
    right: .5rem;
    top: 50%;
    margin: 0;
    padding: 1rem;
    transform: translateY(-50%); }
    .dealer button i {
      font-size: 2.5rem;
      color: #151616;
      transition: all .2s ease-in-out; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .dealer {
    margin-bottom: 5rem; } }

@media (min-width: 1025px) {
  .dealer button:hover i {
    color: #cb2a1e; } }

@media (min-width: 1200px) {
  .dealer {
    padding: 3rem; } }

@media (min-width: 1500px) {
  .dealer {
    padding: 4rem; } }

@media (min-width: 1600px) {
  .dealer {
    margin-bottom: 7rem; } }

/************************************************
* QUOTE
*************************************************/
.quote {
  margin-bottom: 3rem;
  position: relative;
  background-position: center;
  background-size: cover; }
  .quote::after {
    content: '';
    position: absolute;
    background-color: rgba(160, 141, 130, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; }
  .quote .text {
    padding: 0 5%;
    margin: 3rem 0; }
  .quote .cite {
    font-family: "MotionPicture", cursive;
    color: #29170d;
    font-size: 3.5rem;
    line-height: 1;
    text-align: center;
    position: relative;
    z-index: 10; }
  .quote .source {
    font-size: 1.8rem;
    color: #151616;
    text-align: center;
    margin-top: 2rem;
    position: relative;
    z-index: 10; }
    .quote .source::before {
      content: '– '; }
    .quote .source::after {
      content: ' –'; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .quote {
    margin-bottom: 5rem; } }

@media (min-width: 768px) {
  .quote {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .quote .text {
      width: 100%; }
    .quote .cite {
      font-size: 4.2rem; } }

@media (min-width: 1024px) {
  .quote .text {
    width: 75%;
    padding: 0;
    margin: 6rem 0; } }

@media (min-width: 1400px) {
  .quote .text {
    margin: 10rem 0; }
  .quote .cite {
    font-size: 5rem; } }

@media (min-width: 1600px) {
  .quote {
    margin-bottom: 7rem; }
    .quote .source {
      margin-top: 3rem; } }

/************************************************
* FILTER
*************************************************/
.filter {
  margin-bottom: 3rem;
  padding: 3rem 0;
  background-color: #a08d82; }
  .filter .headline {
    font-family: "MotionPicture", cursive;
    font-size: 3.7rem;
    color: white;
    text-align: center;
    margin-bottom: 3rem; }
  .filter .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

.option {
  width: 47%;
  margin-right: 6%; }
  .option:nth-of-type(2n) {
    margin-right: 0; }
  .option .inner {
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: white;
    margin-bottom: 3rem;
    position: relative;
    text-decoration: none;
    display: block;
    position: relative; }
    @media (min-width: 1025px) {
      .option .inner:hover {
        cursor: pointer; }
        .option .inner:hover .text {
          color: #a08d82; }
        .option .inner:hover .icon {
          color: #6b5b51; } }
  .option .text {
    position: absolute;
    font-family: "MotionPicture", cursive;
    font-size: 3rem;
    color: #a08d82;
    text-align: center;
    width: 100%;
    bottom: 2.5rem;
    transition: all .3s ease-in-out; }
  .option .icon {
    font-size: 6rem;
    display: block;
    position: absolute;
    color: #cacaca;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: all .3s ease-in-out; }
    .option .icon i::before {
      margin: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  .option .icon {
    font-size: 8rem;
    top: 40%; } }

@media (min-width: 440px) {
  .option .text {
    font-size: 3.3rem;
    bottom: 3rem; } }

@media (min-width: 500px) {
  .filter {
    margin-bottom: 5rem; } }

@media (min-width: 530px) {
  .filter .headline {
    margin-bottom: 3rem; }
  .filter .content {
    justify-content: center; }
  .option {
    padding: 0 1%;
    width: 31%;
    margin-right: 3.5%; }
    .option:nth-of-type(2n) {
      margin-right: 3.5%; }
    .option:nth-of-type(3n) {
      margin-right: 0; }
    .option .text {
      font-size: 2.8rem;
      bottom: 2.5rem; }
    .option .icon {
      font-size: 6rem; } }

@media (min-width: 700px) {
  .option .text {
    font-size: 3.2rem;
    bottom: 3rem; }
  .option .icon {
    font-size: 8rem; } }

@media (min-width: 750px) {
  .option {
    padding: 0;
    width: 18.5%;
    margin-bottom: 0;
    margin-right: 1.875%; }
    .option:nth-of-type(2n) {
      margin-right: 1.875%; }
    .option:nth-of-type(3n) {
      margin-right: 1.875%; }
    .option:nth-of-type(4n) {
      margin-right: 1.875%; }
    .option:nth-of-type(5n) {
      margin-right: 0; }
    .option .inner {
      margin-bottom: 0; }
    .option .text {
      font-size: 2.6rem;
      bottom: 2.1rem; }
    .option .icon {
      font-size: 5rem; } }

@media (min-width: 850px) {
  .option .icon {
    font-size: 7rem; } }

@media (min-width: 900px) {
  .option .text {
    font-size: 3rem; } }

@media (min-width: 1024px) {
  .filter {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .option .text {
    font-size: 3.2rem;
    bottom: 2.7rem; } }

@media (min-width: 1025px) {
  .option .inner:hover .text {
    color: #5c4e46; } }

@media (min-width: 1100px) {
  .option .icon {
    font-size: 8rem; } }

@media (min-width: 1100px) {
  .option .icon {
    top: 42%; } }

@media (min-width: 1250px) {
  .filter .headline {
    font-size: 4rem; }
  .filter .text {
    font-size: 3.5rem; } }

@media (min-width: 1400px) {
  .option {
    padding: 0 2%; } }

@media (min-width: 1500px) {
  .filter .headline {
    font-size: 4.3rem; } }

@media (min-width: 1600px) {
  .filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 7rem;
    padding-top: 6rem;
    padding-bottom: 6rem; }
    .filter .headline {
      font-size: 4.5rem;
      margin: 0;
      padding-right: 3rem;
      text-align: left;
      align-self: center;
      width: 25%; }
    .filter .content {
      width: 75%; }
  .option {
    padding: 0 .5%; } }

/************************************************
* TEXTTWOCOL
*************************************************/
.textTwoCol {
  margin-bottom: 3rem; }
  .textTwoCol h2 {
    width: 100%; }
  .textTwoCol ul {
    padding: 0; }
    .textTwoCol ul li {
      list-style-type: none;
      font-size: 2rem;
      color: #151616;
      margin-bottom: 1rem;
      padding-left: 2rem;
      position: relative; }
      .textTwoCol ul li::before {
        content: '\e806';
        color: #cb2a1e;
        position: absolute;
        font-size: 2.4rem;
        left: -.5rem;
        top: .2rem; }
      .textTwoCol ul li.checkmark::before {
        content: '\e803';
        color: #cb2a1e;
        position: absolute;
        left: -.5rem;
        top: .3rem; }
  .textTwoCol .col {
    margin-bottom: 4rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .textTwoCol {
    margin-bottom: 5rem; } }

@media (min-width: 600px) {
  .textTwoCol {
    padding-right: 15%; } }

@media (min-width: 700px) {
  .textTwoCol {
    padding-right: 25%; } }

@media (min-width: 1024px) {
  .textTwoCol {
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  h2.fullwidth {
    width: 100%;
    margin: 0; }
  .col {
    width: 48%;
    margin-bottom: 0; } }

@media (min-width: 1400px) {
  .textTwoCol {
    padding: 0 10%; } }

@media (min-width: 1600px) {
  .textTwoCol {
    margin-bottom: 7rem;
    padding: 0 15%; } }

/************************************************
* INGREDIENTS
*************************************************/
.ingredients {
  margin-bottom: 3rem;
  padding: 0 8%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .ingredients h2.fullwidth {
    width: 100%;
    text-align: center; }
  .ingredients .ingredient {
    margin-bottom: 3rem; }
  .ingredients .image {
    margin-bottom: 1rem; }
    .ingredients .image img {
      display: block; }
  .ingredients .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.4;
    color: #151616; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  .ingredients {
    padding: 0 15%; } }

@media (min-width: 500px) {
  .ingredients {
    margin-bottom: 5rem;
    padding: 0 20%; } }

@media (min-width: 600px) {
  .ingredients {
    padding: 0 10%; }
    .ingredients .ingredient {
      width: 48%;
      margin-right: 4%; }
      .ingredients .ingredient:nth-of-type(2n) {
        margin-right: 0; } }

@media (min-width: 670px) {
  .ingredients {
    padding: 0 15%; } }

@media (min-width: 768px) {
  .ingredients {
    padding: 0; }
    .ingredients .ingredient {
      margin-bottom: 0;
      width: 23.5%;
      margin-right: 2%; }
      .ingredients .ingredient:nth-of-type(2n) {
        margin-right: 2%; }
      .ingredients .ingredient:nth-of-type(3n) {
        margin-right: 2%; }
      .ingredients .ingredient:nth-of-type(4n) {
        margin-right: 0; } }

@media (min-width: 1024px) {
  .ingredients h2.fullwidth {
    margin-bottom: 2rem; } }

@media (min-width: 1200px) {
  .ingredients {
    padding: 0 10%; } }

@media (min-width: 1600px) {
  .ingredients {
    padding: 0 15%;
    margin-bottom: 7rem; } }

/************************************************
* PRODUCTHEADER
*************************************************/
.productHeader {
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .productHeader h1 {
    text-align: center;
    width: 100%; }
  .productHeader .image {
    margin-bottom: 3rem;
    width: 100%; }
    .productHeader .image img {
      margin: 0 auto;
      display: block; }
      .productHeader .image img.img2 {
        display: none; }
    .productHeader .image:hover img.img1 {
      display: none; }
    .productHeader .image:hover img.img2 {
      display: block; }
  .productHeader .left,
  .productHeader .right {
    width: 48%; }
    .productHeader .left .image,
    .productHeader .right .image {
      width: 55%;
      position: absolute;
      left: 50%;
      top: 16%;
      transform: translateX(-50%);
      margin: 0; }
    .productHeader .left .text,
    .productHeader .right .text {
      position: absolute;
      font-family: "MotionPicture", cursive;
      font-size: 2.7rem;
      color: #151616;
      text-align: center;
      width: 100%;
      bottom: 10% !important;
      transition: all .3s ease-in-out; }
  .productHeader .option {
    width: 100%; }
    .productHeader .option .inner:hover {
      cursor: inherit; }
  .productHeader .inner {
    background-color: #f1f1f1; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  .productHeader .left .text,
  .productHeader .right .text {
    font-size: 3.3rem; } }

@media (min-width: 500px) {
  .productHeader {
    margin-bottom: 5rem; } }

@media (min-width: 530px) {
  .productHeader {
    padding: 0 10%; } }

@media (min-width: 630px) {
  .productHeader {
    padding: 0 15%; } }

@media (min-width: 730px) {
  .productHeader {
    padding: 0; }
    .productHeader h1 {
      margin-bottom: 3rem; }
    .productHeader .image {
      width: 47%;
      order: 1;
      align-self: center;
      margin: 0; }
    .productHeader .left,
    .productHeader .right {
      width: 23%; }
      .productHeader .left .option:nth-of-type(2n) .inner,
      .productHeader .right .option:nth-of-type(2n) .inner {
        margin: 0; }
      .productHeader .left .text,
      .productHeader .right .text {
        font-size: 3rem; }
    .productHeader .left {
      order: 0; }
    .productHeader .right {
      order: 2; }
    .productHeader .option .inner {
      margin-bottom: 1.5rem; } }

@media (min-width: 950px) {
  .productHeader {
    padding: 0 5%; }
    .productHeader .left .image,
    .productHeader .right .image {
      width: 50%;
      margin-top: 1rem; }
    .productHeader .option {
      width: 90%;
      margin-bottom: 3rem; } }

@media (min-width: 1024px) {
  .productHeader {
    margin-bottom: 7rem;
    padding: 0 8%; }
    .productHeader .option .inner {
      margin-bottom: 2.5rem; }
    .productHeader .left .text,
    .productHeader .right .text {
      font-size: 3.2rem; } }

@media (min-width: 1025px) {
  .productHeader .option:hover .text {
    color: #151616; } }

@media (min-width: 1300px) {
  .productHeader {
    padding: 0 15%; }
    .productHeader .option .inner {
      margin-bottom: 3.5rem; }
    .productHeader .option .image {
      width: 90%; }
    .productHeader .left .image,
    .productHeader .right .image {
      width: 50%;
      margin-top: 1rem; } }

@media (min-width: 1500px) {
  .productHeader .left .text,
  .productHeader .right .text {
    font-size: 3.5rem; } }

@media (min-width: 1600px) {
  .productHeader {
    margin-bottom: 10rem;
    padding: 0 18%; }
    .productHeader .option .inner {
      margin-bottom: 5rem; } }

/************************************************
* IMAGE
*************************************************/
.imageElement {
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .imageElement img {
    display: block; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .imageElement {
    margin-bottom: 5rem; } }

@media (min-width: 1600px) {
  .imageElement {
    margin-bottom: 7rem; } }

/************************************************
* HISTORY
*************************************************/
.history {
  margin-bottom: 3rem; }
  .history h2 {
    margin: 0 0 2rem 0;
    text-align: center;
    width: 100%; }
  .history .entry {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem; }
  .history .inner {
    position: relative;
    padding: 0 0 0 5.5rem; }
    .history .inner::before {
      content: '';
      display: block;
      border-left: 2.5px solid #cacaca;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 3rem;
      width: 1px;
      z-index: 10; }
  .history .year {
    background-color: #f1f1f1;
    border-radius: 50%;
    position: relative;
    width: 100%;
    width: 6.5rem;
    height: 6.5rem;
    margin-bottom: 2rem; }
    .history .year span {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      color: #151616;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .history .image {
    margin-bottom: 2rem; }
    .history .image img {
      display: block; }
  .history .text {
    background-color: #f1f1f1;
    padding: 1.5rem; }
    .history .text h3 {
      color: #cb2a1e;
      margin: 0; }
    .history .text p:first-of-type {
      margin-top: 1rem; }
    .history .text p:last-of-type {
      margin-bottom: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .history {
    margin-bottom: 5rem; }
    .history .year {
      width: 8rem;
      height: 8rem; }
      .history .year span {
        font-size: 2.4rem; }
    .history .inner {
      padding-left: 7rem; }
      .history .inner::before {
        left: 3.75rem; }
    .history .text {
      padding: 2rem; } }

@media (min-width: 700px) {
  .history .year {
    width: 12rem;
    height: 12rem; }
    .history .year span {
      font-size: 3rem; }
  .history .inner {
    padding-left: 12rem; }
    .history .inner::before {
      left: 5.75rem; }
  .history .text {
    padding: 3rem; } }

@media (min-width: 840px) {
  .history h2 {
    margin-bottom: 3rem; }
  .history .year {
    margin-bottom: 4rem; }
  .history .entry {
    justify-content: center;
    margin-bottom: 4rem; }
    .history .entry:nth-of-type(2n) .inner .image {
      order: 2; }
  .history .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0; }
    .history .inner::before {
      left: 50%; }
  .history .image {
    width: 46%;
    margin-bottom: 0; }
  .history .text {
    width: 46%;
    padding: 2rem; } }

@media (min-width: 1300px) {
  .history .text {
    padding: 3rem; } }

@media (min-width: 1500px) {
  .history .image,
  .history .text {
    width: 42%;
    align-self: center; }
  .history h2 {
    margin-bottom: 5rem; } }

@media (min-width: 1600px) {
  .history {
    margin-bottom: 7rem; }
    .history .image,
    .history .text {
      width: 40%; } }

/************************************************
* MAPS
*************************************************/
.maps {
  margin-bottom: 4rem; }
  .maps .embed-container {
    position: relative;
    padding-bottom: 70%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto; }
  .maps .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .maps {
    margin-bottom: 5rem; } }

@media (min-width: 1600px) {
  .maps {
    margin-bottom: 7rem; } }

/************************************************
* PRODUCTDETAILS
*************************************************/
.productDetails {
  margin-bottom: 3rem;
  background-color: #cb2a1e;
  padding-top: 3rem;
  padding-bottom: 4rem; }
  .productDetails .nutritionFacts h2 {
    color: white;
    margin-top: 0; }
  .productDetails .nutritionFacts .box {
    background-color: white;
    padding: 1.5rem; }
  .productDetails .nutritionFacts h3 {
    font-size: 1.6rem;
    text-transform: uppercase;
    margin: 0 0 .5rem 0;
    color: #111111;
    font-weight: 500; }
  .productDetails .nutritionFacts h3 + p {
    margin-top: 0; }
  .productDetails .nutritionFacts p + h3 {
    margin-top: 2rem; }
  .productDetails .nutritionFacts p {
    font-size: 1.7rem;
    color: #151616; }
    .productDetails .nutritionFacts p.small {
      font-size: 1.5rem; }
  .productDetails .nutritionFacts ul {
    padding: 0; }
    .productDetails .nutritionFacts ul li {
      list-style-type: none;
      font-size: 1.7rem;
      padding: .5rem 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      color: #151616; }
      .productDetails .nutritionFacts ul li:nth-of-type(2n) {
        background-color: #f1f1f1; }
      .productDetails .nutritionFacts ul li strong {
        width: 48%;
        font-weight: normal;
        margin-right: 4%; }
      .productDetails .nutritionFacts ul li span {
        width: 48%; }
  .productDetails .dealer {
    padding: 0;
    margin: 6rem 0 0 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .productDetail {
    margin-bottom: 5rem; } }

@media (min-width: 768px) {
  .productDetails .dealer {
    padding-right: 25%; } }

@media (min-width: 800px) {
  .productDetails .nutritionFacts {
    padding-right: 25%; } }

@media (min-width: 1000px) {
  .productDetails {
    padding-top: 4rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .productDetails .nutritionFacts {
      width: 48%;
      padding: 0; }
      .productDetails .nutritionFacts h2 {
        width: 100%; }
    .productDetails .dealer {
      width: 48%;
      padding: 0;
      margin: 0;
      align-self: center; } }

@media (min-width: 1300px) {
  .productDetails .nutritionFacts .box {
    padding: 2.5rem; } }

@media (min-width: 1500px) {
  .productDetails {
    padding-top: 6rem;
    padding-bottom: 7rem; } }

@media (min-width: 1600px) {
  .productDetails {
    margin-bottom: 7rem; }
    .productDetails .nutritionFacts .box {
      padding: 3.5rem; } }

/************************************************
* ANIMATIONS
*************************************************/
/* hide elements first */
.hide {
  opacity: 0; }

/* fadeInUp effect from animate.css */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 12%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp-active {
  animation: fadeInUp 1s; }

/* fadeIn effect from animate.css */
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn-active {
  animation: fadeIn 2s; }

/* FadeInLeft effect from animate.css */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-8%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft-active {
  animation: fadeInLeft 1s; }

/************************************************
* formLayout0
*************************************************/
.formLayout0 {
  padding: 3rem 0;
  /* static text */
  /* immer um ein form element (label + div) */
  /* Grid Row */
  /* Label */
  /* Fieldset */
  /* Input & Textarea */
  /* Submit */ }
  .formLayout0 h1,
  .formLayout0 h2 {
    margin-top: 0; }
  .formLayout0 h2 + div {
    margin-bottom: 4rem; }
  .formLayout0 a {
    text-decoration: none;
    color: #cb2a1e; }
    @media (min-width: 1025px) {
      .formLayout0 a:hover {
        color: rgba(203, 42, 30, 0.7); } }
  .formLayout0 .form-group {
    margin-bottom: 3rem; }
  .formLayout0 .row {
    width: 100%;
    margin-bottom: 2rem; }
    .formLayout0 .row + .row h2 {
      margin-top: 3rem; }
  .formLayout0 label {
    width: 100%;
    color: #151616;
    font-size: 2rem;
    display: block;
    margin-bottom: 1rem; }
  .formLayout0 fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formLayout0 fieldset legend {
      font-size: 2rem;
      font-weight: 500;
      color: #cb2a1e;
      margin-bottom: 2rem;
      padding: 0; }
    .formLayout0 fieldset > .form-group,
    .formLayout0 fieldset .clearfix {
      margin-bottom: 1rem; }
  .formLayout0 .input {
    width: 100%; }
    .formLayout0 .input input,
    .formLayout0 .input textarea {
      border: none;
      padding: 1.3rem;
      width: 100%;
      font-family: "Palanquin", sans-serif;
      font-size: 2rem;
      color: #151616;
      background-color: #f2f2f2; }
    .formLayout0 .input textarea {
      height: 20rem; }
  .formLayout0 .actions {
    padding: 3rem 0 0 0;
    width: 100%;
    text-align: right; }
    .formLayout0 .actions button {
      background-color: #cb2a1e;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1.9rem;
      font-weight: 300;
      padding: 1.2rem 2rem;
      transition: .3s ease-in-out; }

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/
.select2-container--default {
  font-size: 2rem;
  width: 40%;
  /* Hintergrund für aktive Auswahl*/
  /* Hintergrund für gehoverte Auswahl*/ }
  .select2-container--default .select2-selection--single {
    background-color: white;
    font-size: 2rem;
    border-radius: 0;
    border: none;
    padding: 1.7rem 1.3rem;
    padding-right: 4rem;
    height: auto; }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: auto;
      bottom: 0;
      width: 5rem;
      top: 0;
      right: 0;
      border: 1px solid #7f7f7f;
      background-color: #7f7f7f;
      border-left: 0; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none; }
        .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
          content: '\e807';
          position: absolute;
          top: -.8rem;
          left: -.8rem;
          font-size: 2.5rem;
          color: white; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #151616;
      font-size: 2rem;
      padding: 0; }
      .select2-container--default .select2-selection--single .select2-selection__rendered li:before {
        content: ''; }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: none; }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
      content: '\e80a'; }
  .select2-container--default .select2-results__option {
    padding: 1rem;
    color: #151616; }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #f2f2f2; }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #d3d3d3;
    color: #151616; }
  .select2-container--default .select2-selection--multiple {
    font-size: 2rem;
    border-radius: 0;
    padding: .8rem .7rem .7rem .7rem;
    padding-right: 4rem;
    height: auto;
    line-height: 1.8rem; }
    .select2-container--default .select2-selection--multiple li {
      line-height: 1.5;
      font-size: 2rem; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: .2rem; }
    .select2-container--default .select2-selection--multiple .select2-search--inline::before {
      content: ''; }
      .select2-container--default .select2-selection--multiple .select2-search--inline::before input {
        font-size: 2rem; }

/* Hintergrund generell für die Optionen */
.select2-dropdown {
  background-color: white;
  border-color: #a7a7a7;
  border-width: 1px;
  border-radius: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  .select2-container--default {
    width: 30%; } }

@media (min-width: 600px) {
  .select2-container--default {
    width: 20%; } }

@media (min-width: 768px) {
  .formLayout0 {
    padding: 5rem 0; } }

@media (min-width: 800px) {
  .select2-container--default {
    width: 17%; } }

@media (min-width: 900px) {
  form > .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    form > .row > div.row {
      width: 48%; }
  .formLayout0 .row {
    margin-bottom: 0; }
  .formLayout0 .inputs-list .form-check {
    width: 48%; }
  .formLayout0 .row .row div:last-child .form-group {
    margin-bottom: 0; }
  .formLayout0 > form > .row > div:last-child > .row {
    height: 100%; }
    .formLayout0 > form > .row > div:last-child > .row > div:last-child {
      height: 60%; }
      .formLayout0 > form > .row > div:last-child > .row > div:last-child > div {
        height: 100%; }
        .formLayout0 > form > .row > div:last-child > .row > div:last-child > div textarea {
          height: 95.5%; }
  .select2-container--default {
    width: 30%; } }

@media (min-width: 1024px) {
  .formLayout0 {
    padding: 4rem 0; }
  .select2-container--default {
    width: 25%; } }

@media (min-width: 1200px) {
  .formLayout0 {
    padding: 5rem 0; } }

@media (min-width: 1300px) {
  .formLayout0 .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4rem; }
  .formLayout0 label {
    width: 22%;
    margin: 0;
    padding-top: 1.5rem; }
  .formLayout0 .input {
    width: 75%; }
  .formLayout0 > form > .row > div:last-child > .row > div:last-child > div textarea {
    height: 100%; } }

@media (min-width: 1550px) {
  .select2-container--default {
    width: 22%; } }

/************************************************
* formLayout10
*************************************************/
main > .inner > .wrap > div.formLayout10 {
  margin-left: -4.348%;
  margin-right: -4.348%;
  width: 108.696%;
  padding-left: 5.588%;
  padding-right: 5.588%; }

.formLayout10 {
  background-color: #f1f1f1;
  padding: 3rem 0;
  /* static text */
  /* immer um ein form element (label + div) */
  /* Grid Row */
  /* Label */
  /* Fieldset */
  /* Input & Textarea */
  /* Submit */ }
  .formLayout10 h1,
  .formLayout10 h2 {
    margin-top: 0; }
  .formLayout10 h2 + div {
    margin-bottom: 4rem; }
  .formLayout10 a {
    text-decoration: none;
    color: #cb2a1e; }
    @media (min-width: 1025px) {
      .formLayout10 a:hover {
        color: rgba(203, 42, 30, 0.7); } }
  .formLayout10 .form-group {
    margin-bottom: 3rem; }
  .formLayout10 .row {
    width: 100%;
    margin-bottom: 2rem; }
    .formLayout10 .row + .row h2 {
      margin-top: 3rem; }
  .formLayout10 label {
    width: 100%;
    color: #151616;
    font-size: 2rem;
    display: block;
    margin-bottom: 1rem; }
    .formLayout10 label.form-check-label > span {
      display: block;
      margin-right: .5em;
      width: 90%;
      width: calc(100% - 45px);
      float: right;
      font-weight: 300;
      line-height: 1.2em; }
  .formLayout10 fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formLayout10 fieldset legend {
      font-size: 2rem;
      font-weight: 500;
      color: #cb2a1e;
      margin-bottom: 2rem;
      padding: 0; }
    .formLayout10 fieldset > .form-group,
    .formLayout10 fieldset .clearfix {
      margin-bottom: 1rem; }
  .formLayout10 .input {
    width: 100%; }
    .formLayout10 .input input[type=text],
    .formLayout10 .input textarea {
      border: none;
      padding: 1.3rem;
      width: 100%;
      font-family: "Palanquin", sans-serif;
      font-size: 2rem;
      color: #151616; }
    .formLayout10 .input textarea {
      min-height: 20rem; }
  .formLayout10 .actions {
    padding: 3rem 0 0 0;
    width: 100%;
    text-align: right; }
    .formLayout10 .actions button {
      background-color: #cb2a1e;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1.9rem;
      font-weight: 300;
      padding: 1.2rem 2rem;
      transition: .3s ease-in-out; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 768px) {
  .formLayout10 {
    padding: 5rem 0; }
  main > .inner > .wrap > div.formLayout10 {
    margin-left: -3.191%;
    margin-right: -3.191%;
    width: 106.383%;
    padding-left: 4.431%;
    padding-right: 4.431%; } }

@media (min-width: 900px) {
  form > .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    form > .row > div {
      width: 48%; }
  .formLayout10 .row {
    margin-bottom: 0; }
  .formLayout10 .inputs-list .form-check {
    width: 48%; }
  .formLayout10 .row .row div:last-child .form-group {
    margin-bottom: 0; } }

@media (min-width: 1024px) {
  .formLayout10 {
    padding: 4rem 0; } }

@media (min-width: 1200px) {
  .formLayout10 {
    padding: 5rem 0; } }

@media (min-width: 1400px) {
  .formLayout10 form {
    padding: 0 10%; } }

@media (min-width: 1600px) {
  .formLayout10 form {
    padding: 0 15%; } }

@media (min-width: 1670px) {
  main > .inner > .wrap > div.formLayout10 {
    margin-left: -150px;
    margin-right: -150px;
    width: 1950px;
    padding-left: 175px;
    padding-right: 175px; } }

/************************************************
* formLayout20
*************************************************/
main > .inner > .wrap > div.formLayout20 {
  margin-left: -4.348%;
  margin-right: -4.348%;
  width: 108.696%;
  padding-left: 5.588%;
  padding-right: 5.588%; }

.formLayout20 {
  background-color: #f1f1f1;
  padding: 3rem 0;
  /* static text */
  /* immer um ein form element (label + div) */
  /* Grid Row */
  /* Label */
  /* Fieldset */
  /* Input & Textarea */
  /* Submit */ }
  .formLayout20 h1,
  .formLayout20 h2 {
    margin-top: 0; }
  .formLayout20 h2 + div {
    margin-bottom: 4rem; }
  .formLayout20 a {
    text-decoration: none;
    color: #cb2a1e; }
    @media (min-width: 1025px) {
      .formLayout20 a:hover {
        color: rgba(203, 42, 30, 0.7); } }
  .formLayout20 .form-group {
    margin-bottom: 3rem; }
  .formLayout20 .row {
    width: 100%;
    margin-bottom: 2rem; }
    .formLayout20 .row + .row h2 {
      margin-top: 3rem; }
  .formLayout20 label {
    width: 100%;
    color: #151616;
    font-size: 2rem;
    display: block;
    margin-bottom: 1rem; }
    .formLayout20 label.form-check-label > span {
      display: block;
      margin-right: .5em;
      width: 90%;
      width: calc(100% - 45px);
      float: right;
      font-weight: 300;
      line-height: 1.2em; }
  .formLayout20 fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formLayout20 fieldset legend {
      font-size: 2rem;
      font-weight: 500;
      color: #cb2a1e;
      margin-bottom: 2rem;
      padding: 0; }
    .formLayout20 fieldset > .form-group,
    .formLayout20 fieldset .clearfix {
      margin-bottom: 1rem; }
  .formLayout20 .input {
    width: 100%; }
    .formLayout20 .input input[type=text],
    .formLayout20 .input textarea {
      border: none;
      padding: 1.3rem;
      width: 100%;
      font-family: "Palanquin", sans-serif;
      font-size: 2rem;
      color: #151616; }
    .formLayout20 .input textarea {
      height: 20rem; }
  .formLayout20 .actions {
    padding: 3rem 0 0 0;
    width: 100%;
    text-align: right; }
    .formLayout20 .actions button {
      background-color: #cb2a1e;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1.9rem;
      font-weight: 300;
      padding: 1.2rem 2rem;
      transition: .3s ease-in-out; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 768px) {
  .formLayout20 {
    padding: 5rem 0; }
  main > .inner > .wrap > div.formLayout20 {
    margin-left: -3.191%;
    margin-right: -3.191%;
    width: 106.383%;
    padding-left: 4.431%;
    padding-right: 4.431%; } }

@media (min-width: 900px) {
  form > .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    form > .row > div {
      width: 48%; }
  .formLayout20 .row {
    margin-bottom: 0; }
  .formLayout20 .inputs-list .form-check {
    width: 48%; }
  .formLayout20 .row .row div:last-child .form-group {
    margin-bottom: 0; } }

@media (min-width: 1024px) {
  .formLayout20 {
    padding: 4rem 0; } }

@media (min-width: 1200px) {
  .formLayout20 {
    padding: 5rem 0; } }

@media (min-width: 1400px) {
  .formLayout20 form {
    padding: 0 10%; } }

@media (min-width: 1600px) {
  .formLayout20 form {
    padding: 0 15%; } }

@media (min-width: 1670px) {
  main > .inner > .wrap > div.formLayout20 {
    margin-left: -150px;
    margin-right: -150px;
    width: 1950px;
    padding-left: 175px;
    padding-right: 175px; } }

/************************************************
* IMAGE
*************************************************/
.callToAction {
  margin-bottom: 3rem;
  background-color: #cb2a1e;
  background-position: center;
  background-size: cover; }
  .callToAction .content {
    padding: 0 5%;
    margin: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .callToAction .text {
    font-family: "MotionPicture", cursive;
    color: white;
    font-size: 3.5rem;
    line-height: 1;
    text-align: center;
    position: relative;
    z-index: 10;
    width: 100%; }
  .callToAction a.button {
    margin-top: 3rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .callToAction {
    margin-bottom: 5rem; }
    .callToAction .content {
      padding: 0; } }

@media (min-width: 768px) {
  .callToAction {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .callToAction .content {
      width: 100%; }
    .callToAction .text {
      font-size: 4.2rem; } }

@media (min-width: 1024px) {
  .callToAction .content {
    width: 75%;
    margin: 6rem 0; }
  .callToAction a.button {
    margin-top: 5rem; } }

@media (min-width: 1400px) {
  .callToAction .content {
    margin: 10rem 0; }
  .callToAction .text {
    font-size: 5rem; } }

@media (min-width: 1600px) {
  .callToAction {
    margin-bottom: 7rem; } }

/************************************************
* merchantForm
*************************************************/
.merchantForm {
  background-color: #cb2a1e;
  margin-bottom: 6rem; }
  .merchantForm .text {
    padding: 3rem; }
    .merchantForm .text p, .merchantForm .text h2, .merchantForm .text h3 {
      color: #fff; }
    .merchantForm .text h2 + p {
      margin-top: 0; }
    .merchantForm .text p:last-of-type {
      margin-bottom: 0; }

form.merchantForm {
  background-color: #cb2a1e;
  padding: 3rem;
  display: flex;
  position: relative; }
  form.merchantForm input {
    border: none;
    font-size: 1.8rem;
    font-family: 'Open Sans', sans-serif;
    color: #151616;
    padding: 1rem 6rem 1rem 1.5rem;
    border-radius: 0;
    width: 100%; }
  form.merchantForm ::placeholder {
    color: #151616; }
  form.merchantForm button {
    border: none;
    position: absolute;
    display: block;
    background-color: #fff;
    color: #151616;
    right: 3rem;
    top: 50%;
    margin: 0;
    padding: .1rem 1rem;
    transform: translateY(-50%); }
    form.merchantForm button i {
      font-size: 2.5rem;
      color: #151616;
      transition: all .2s ease-in-out; }

.merchantList {
  font-size: 1.4rem; }
  .merchantList .item {
    margin-bottom: 2rem; }
    .merchantList .item .merchant {
      margin-bottom: 1rem;
      display: block; }
      .merchantList .item .merchant address {
        font-style: normal; }
      .merchantList .item .merchant .postcode, .merchantList .item .merchant .locality {
        display: inline-block;
        margin-right: .5rem; }
      .merchantList .item .merchant a {
        color: #cb2a1e; }
    .merchantList .item .googleMaps {
      flex: 0 1 50%;
      max-width: 500px;
      height: 200px; }
    .merchantList .item iframe {
      border: 0px;
      width: 100%;
      height: 100%; }

ul.f3-widget-paginator {
  flex: 0;
  clear: both;
  margin: 2em 0;
  padding: 0;
  list-style: none;
  font-size: 1.6rem; }
  ul.f3-widget-paginator li {
    display: inline-block;
    margin-right: 6px;
    color: #460e0a; }
    ul.f3-widget-paginator li.current {
      color: #460e0a;
      padding: 0.3em .3em; }
  ul.f3-widget-paginator li, ul.f3-widget-paginator a {
    color: #cb2a1e; }
  ul.f3-widget-paginator a {
    padding: 0.3em .3em;
    display: inline-block;
    text-decoration: none; }
    ul.f3-widget-paginator a:hover {
      text-decoration: underline; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .merchantForm {
    margin-bottom: 5rem; } }

@media screen and (min-width: 700px) {
  .merchantList .item {
    display: flex; }
    .merchantList .item .merchant {
      flex: 1 1 auto; } }

@media (min-width: 1600px) {
  .merchantForm {
    margin-bottom: 7rem; } }

.instagram {
  text-align: center;
  padding-bottom: 7rem; }
  .instagram .sliderNavWrapper {
    position: relative; }
    .instagram .sliderNavWrapper .owl-prev,
    .instagram .sliderNavWrapper .owl-next {
      position: absolute;
      bottom: 38%;
      z-index: 2;
      font-size: 3rem;
      color: #cb2a1e; }
      .instagram .sliderNavWrapper .owl-prev i:hover,
      .instagram .sliderNavWrapper .owl-next i:hover {
        cursor: pointer; }
    .instagram .sliderNavWrapper .owl-prev {
      left: 0; }
    .instagram .sliderNavWrapper .owl-next {
      right: 0; }
  .instagram #instaSlider {
    z-index: 1; }
  .instagram .owl-item img {
    max-width: 100%;
    max-height: auto; }

@media only screen and (min-width: 1024px) {
  .instagram #instaSlider {
    padding: 0 5em; } }

/************************************************
* FOOTER
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
/* myfonts counter */
footer {
  background-color: #29170d;
  padding: 2rem 0;
  font-size: 1.8rem; }
  footer .buttonLinks {
    width: 100%;
    display: inline-block;
    padding-bottom: 3rem; }
    footer .buttonLinks a {
      color: #fff;
      display: inline-block;
      font-size: 1.8rem;
      text-decoration: none;
      transition: .3s ease-in-out;
      white-space: nowrap; }
      footer .buttonLinks a::before {
        background-color: #cb2a1e;
        color: #fff;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        margin-bottom: .5rem;
        margin-right: 1rem;
        min-width: 5rem;
        padding: .8rem;
        position: relative;
        text-align: center;
        top: .2rem;
        transition: .3s ease-in-out; }
      footer .buttonLinks a.instagram::before {
        content: '\f16d';
        font-family: sanbeam-icons;
        font-size: 3rem; }
      footer .buttonLinks a.partnerInfos::before {
        content: 'B2B'; }
      footer .buttonLinks a:hover::before {
        border-radius: 20px; }
  footer .footerNav {
    width: 100%; }
    footer .footerNav div a {
      color: white;
      text-decoration: none;
      font-weight: normal;
      transition: all .2s ease-in-out;
      font-size: 1.9rem; }
    footer .footerNav div ul {
      margin: 0;
      padding: 0 0 1rem 0;
      list-style: none;
      display: block; }
    footer .footerNav div li {
      display: block; }
  footer p {
    color: #e7c6b4;
    margin-top: 0;
    line-height: 1.5em; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 500px) {
  .buttonLinks a {
    margin: 0 2rem; } }

@media (min-width: 640px) {
  footer .buttonLinks {
    text-align: center; }
  footer .footerNav {
    flex-wrap: wrap;
    display: flex; }
    footer .footerNav div {
      flex: 1 1 50%;
      padding-right: 2%; } }

@media (min-width: 768px) {
  footer .footerNav a {
    font-size: 1.6rem;
    margin: 0; }
  footer p a.phone {
    color: #e1b8a0; } }

@media (min-width: 1024px) {
  footer {
    padding: 3rem 0; }
    footer .footerNav div {
      flex: 1 1 25%;
      padding-right: 2%; }
    footer .footerNav ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    footer .footerNav a {
      font-size: 1.8rem; } }

@media (min-width: 1025px) {
  footer .footerNav a:hover {
    color: #a08d82; } }

@media (min-width: 1400px) {
  footer {
    padding: 4rem 0; } }
